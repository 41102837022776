import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { TopMenu, Footer } from './components/HeaderFooter';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-clock/dist/Clock.css';
import "react-image-gallery/styles/css/image-gallery.css"
import { Introduction, MainPage, NotFound, WorkWithUs } from './components/Index'
import { ContactUs } from './components/Contact';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<><TopMenu/><div className="middle-stuff"><MainPage/></div><Footer/></>}/>
        <Route exact path="/introduction" element={<><TopMenu/><div className="middle-stuff"><Introduction/></div><Footer/></>}/>
        <Route exact path="/work-with-us" element={<><TopMenu/><div className="middle-stuff"><WorkWithUs/></div><Footer/></>}/>
        <Route exact path="/contact-us" element={<><TopMenu/><div className="middle-stuff"><ContactUs/></div><Footer/></>}/>
        <Route path="*" element={<><TopMenu/><div className="middle-stuff"><NotFound/></div><Footer/></>}/>
      </Routes>
    </Router>
  );
}

export default App;