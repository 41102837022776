import { Link } from 'react-router-dom'
import {Navbar, Container, Nav } from 'react-bootstrap';

export const TopMenu = () => {
  return ( 
  <>
  <div className="middle-stuff" style={{paddingLeft:20, paddingRight:20}}>
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
      <Navbar.Brand href="/"><img alt="" src="img\trfsm_logo.svg" height="60"/></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav defaultActiveKey="home" className="me-auto" style={{fontWeight:"bold"}}>
          <Nav.Link eventKey="home" as={Link} to="/">HOME</Nav.Link>
          {/* <Nav.Link eventKey="introduction" as={Link} to="/introduction">INTRODUCTION</Nav.Link> */}
          <Nav.Link eventKey="work-with-us" as={Link} to="/work-with-us">WORK WITH US</Nav.Link>
          <Nav.Link eventKey="contact-us" as={Link} to="/contact-us">CONTACT US</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
    <div className="mt-3 pt-3 pb-3" style={{backgroundColor:'#002d6c'}}>
    </div>
  </>  
  )
}

export const Footer = () => {
  return (
  <div className="mt-3 pt-3 pb-3 footer" style={{backgroundColor:'#002d6c'}}>
    <div className="container">
      <div className="row mt-3">
        <div className="col copyright">
            <p className="text-white">All contents copyrighted ©{new Date().getFullYear()} TRF SM</p>
            <img src="./img/af-1.svg" alt="" height="30" />
        </div>
      </div>
    </div>
  </div>
  );
}