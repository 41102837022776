import ImageGallery from 'react-image-gallery';

export const MainPage = () => {
  const images = [
    {
      original: '/img/fp1.jpg',
      originalHeight : 400,
    },
    {
      original: '/img/fp2.jpg',
      originalHeight : 400,
    },
    {
      original: '/img/fp3.jpg',
      originalHeight : 400,
    },
  ];
  return (
    <>
      <div className="photo-carousel">
        <ImageGallery 
          items={images} 
          showFullscreenButton={false} 
          showThumbnails={false} 
          autoPlay 
          showBullets 
          slideInterval={5000} 
          showNav={false}
        />
      </div>
      <div style={{padding:20}}>
      <p><b>TRF Ship Management AS</b> was established in the spring of 2014 to oversee and monitor a portfolio of shipping investments on behalf of Transportation Recovery Fund (TRF).</p>
      <p>TRF has ownership in about fifty ships, in the bulk, chemical, crude and container segments.</p>
      <p>TRF Ship Management AS oversees and coordinates:</p>
      <ul>
        <li>Commercial management</li>
        <li>Technical management</li>
        <li>Operations</li>
        <li>Financing</li>
        <li>Accounting</li>
        <li>Insurance</li>
      </ul>
      <p>TRF has outsourced technical and commercial management to third party providers, and TRF Ship Management AS oversees these management companies.</p>
      </div>
    </>
  )
}

export const Introduction = () => {
  return (
      <div style={{paddingBottom:"50px"}}>
        <h1>Introduction</h1>
        <img src="/img/Pipa_medium.jpg" alt="" className="left"/>
        <p><b>TRF Ship Management AS</b> is responsible for overseeing a portfolio of shipping investments on behalf of <b>Transportation Recovery Fund</b> <b>(TRF)</b>.</p>
        <p>TRF has ownership in about fifty ships, in the bulk, chemical, crude and container segments.</p>
        <p>TRF has outsourced technical and commercial management to third party providers, and TRF Ship Management AS oversees these management companies.</p>
      </div>
  )
}

export const WorkWithUs = () => {
  return (
    <div>
      <h1>WORK WITH US</h1>
      <p>22.02.2021</p>
      <p>We have no open positions at the moment.</p>
    </div>
  )
}

export const NotFound = () => {
  return <div style={{padding:30}}><h1>Page Not Found</h1>
  <p>Sorry, but the page you were trying to view does not exist.</p></div>
}