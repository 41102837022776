import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Container } from 'react-bootstrap';
import ReactMapGL, {Source, Layer} from 'react-map-gl';
import Clock from 'react-clock';

const employees = [
  {
    name: 'Michael Aasland',
    vCardUrl: '/vcards/michael_aasland.vcf',
    title: 'CEO',
    email: 'maa$spam@trfsm.no'
  },
  {
    name: 'Anders Hessevik',
    vCardUrl: '/vcards/anders_hessevik.vcf',
    title: 'Fleet Director',
    email: 'ahe$spam@trfsm.no'
  },
  {
    name: 'Anne Jorunn Medhus',
    vCardUrl: '/vcards/anne_jorunn_medhus.vcf',
    title: 'CFO',
    email: 'ajm$spam@trfsm.no'
  },
]

export const ContactUs = () => {
  const [viewport, setViewport] = useState({
    latitude: 59.90876578763894,
    longitude: 10.756945863007353, 
    zoom: 13
  });
  const [clockValue, setClockValue] = useState(new Date());
 
  useEffect(() => {
    const interval = setInterval(
      () => setClockValue(new Date()),
      1000
    );
 
    return () => {
      clearInterval(interval);
    }
  }, []);

  const geojson = {
    type: 'FeatureCollection',
    features: [
      {type: 'Feature', geometry: {type: 'Point', coordinates: [10.756945863007353, 59.90876578763894]}}
    ]
  };
  
  const layerStyle = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 10,
      'circle-color': '#007cbf'
    }
  };

  const handleSpam = (event) => {
    let emailAddress = 'mailto:'+event.target.value.replace('$spam','')
    window.open(emailAddress)
  }

  return (<>
  <Container fluid>
    <Card style={{padding:10, margin:10}}>
      <Row xs={1} md={2} lg={2}>
        <Col>
          <div style={{padding:20}}>
            <Clock value={clockValue} size={70}/>
          </div>
          <p><b>Post address:</b><br/>
            TRF Ship Management AS<br/>
            P.O.Box 1158 Sentrum,<br/>
            N-0107 Oslo<br/>
            Norway</p>
          <p><b>Visiting address:</b> <br/>
            TRF Ship Management AS<br/>
            Dronning Eufemias gate 8, 10. etg.<br/>
            0191 Oslo<br/>
            Norway</p>
        </Col>
        <Col>
          <div style={{height:400}}>
          <ReactMapGL
            {...viewport}
            mapboxApiAccessToken="pk.eyJ1IjoiaG9nbmUiLCJhIjoiY2t0MTkwbDZnMDg0OTJwczJrdmdzdGJrayJ9.i_va34LRIQrIfmoF6FM2HA"
            width="100%"
            height="100%"
            onViewportChange={(viewport) => setViewport(viewport)}
            >
            <Source id="my-data" type="geojson" data={geojson}>
              <Layer {...layerStyle} />
            </Source>
          </ReactMapGL>
          </div>
          </Col>
        </Row>
      </Card>
    <Row xs={1} md={2} lg={3}>
      {employees.map((e, key) => <Col key={key}>
        {<Card style={{padding:"10px", margin:"10px"}}>
          <p className="bold name">
            <a href={e.vCardUrl} title="Download vcard">
            {e.name}<i style={{float:"right"}} className="fa fa-download"></i>
            </a>
          </p>
          <p>
            {e.title} 
          </p>
          <table>
            <tbody> 
              <tr>
                <td style={{fontWeight:"bold"}}>Email:</td><td align="right">
                  <button style={{border:'none',color:'#0D6EFD',textDecoration:'underline',backgroundColor:'#fff'}} value={e.email} onClick={handleSpam}>
                    {e.email.split('$spam')[0]}<span style={{display:'none'}}>$spam</span>[at]trfsm.no
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
      </Card>}
      </Col>)}
    </Row>
    </Container>
  </>
  );
};
